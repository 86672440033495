.modal-width {
  width: 85vw;
  max-width: 85vw;
}

Col {
  margin-bottom: 1rem;
}

#floatingInput {
  marginbottom: '1rem';
}